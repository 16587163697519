import '@fontsource/sue-ellen-francisco';
import BlockContent from '@sanity/block-content-to-react';
import { PageProps, graphql } from 'gatsby';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { Query, SanityProduct } from 'src/graphql-types';

import Layout from '../layout';
import { serializers } from '../serializers';

export const query = graphql`
  query IndexPageQuery {
    allSanityHome(limit: 1) {
      edges {
        node {
          title
          _rawBlurb
          image {
            asset {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
          featuredProducts {
            slug {
              current
            }
            name
            options {
              name
              price
              feeds
            }
            images {
              asset {
                gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`;

interface IndexPageProps extends PageProps {
  data: Query;
}

const IndexPage = ({ data, location }: IndexPageProps) => {
  const { title, image, _rawBlurb, featuredProducts } =
    data.allSanityHome.edges[0].node;

  return (
    <Layout location={location}>
      <div
        className="grid w-full grid-cols-1 gap-10 p-4 bg-white md:p-10 md:grid-cols-2"
        style={{ maxWidth: 1200 }}
      >
        {featuredProducts.map((item) => {
          return <Item item={item} key={item.slug.current} />;
        })}
        <div className="flex items-center overflow-hidden rounded max-h-96">
          <GatsbyImage
            image={image.asset.gatsbyImageData}
            alt="Gray + Mae Owner"
          />
        </div>
        <div className="flex items-center">
          <div>
            <h2 className="mt-0">{title}</h2>
            <BlockContent blocks={_rawBlurb} serializers={serializers} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;

interface ItemProps {
  item: SanityProduct;
}

const Item = ({ item }: ItemProps) => {
  const image = item.images[0];
  const startingAt = item.options[0].price;
  const page = `product/${item.slug.current}`;
  const all: number[] = [];

  item.options.forEach(({ feeds }) => {
    all.push(...feeds.split('-').map((num) => +num));
  });

  return (
    <div className="relative w-full h-full transition-shadow transition-transform transform hover:scale-101 hover:shadow-lg">
      <Link
        to={page}
        className="block overflow-hidden no-underline rounded max-h-96"
      >
        <GatsbyImage image={image.asset.gatsbyImageData} alt={item.name} />
      </Link>

      <div className="absolute bottom-0 z-10 flex flex-col items-center w-full p-4 text-white bg-gray-800 bg-opacity-75 rounded-b pointer-events-none md:flex-row backdrop-blur backdrop-filter">
        <Link
          className="mb-3 text-3xl text-center no-underline uppercase md:mb-0 font-display h-7 md:text-left"
          to={page}
        >
          {item.name}
        </Link>
        <div className="flex ml-0 space-x-10 text-center md:text-right md:ml-auto">
          <p className="mb-0">
            <span className="block text-xs opacity-75">Starting at</span>
            <strong className="block text-xl">${startingAt}</strong>
          </p>
          <p className="mb-0">
            <span className="block text-xs opacity-75">Can Feed</span>
            <strong className="block text-xl">
              {Math.min(...all)} - {Math.max(...all)}
            </strong>
          </p>
        </div>
      </div>
    </div>
  );
};
